import dynamic from 'next/dynamic'
import type { SitewideBanner } from '@/types/Contentful/SitewideBanner'
import { isValidCountDownDate } from './CountdownTimer'

const Link = dynamic(import('@csc/dls/Link'))
const Text = dynamic(import('@csc/dls/Text'))
const IconRenderer = dynamic(import('@csc/dls/IconRenderer'))
const Play = dynamic(import('@csc/dls/Icons/Play'))
const Pause = dynamic(import('@csc/dls/Icons/Pause'))

const CountdownTimer = dynamic(import('./CountdownTimer'))

type BannerV1Props = (
  Pick<
  SitewideBanner,
  'messaging' |
  'callToActionUrl' |
  'countdownTarget'
  > & {
    callToActionText?: string
  } & ({
    scrollable?: false
    isScrolling?: false
    onTogglePlayPause: undefined,
    onSelectTile: undefined
    tilesLenght: undefined
    selectedIndex: undefined
  } | {
    scrollable: true
    isScrolling: boolean
    onTogglePlayPause: () => void
    onSelectTile: (p: number) => void
    tilesLenght: number
    selectedIndex: number
  })
)

const BannerV1: React.FC<BannerV1Props> = ({
  messaging,
  callToActionUrl,
  countdownTarget,
  isScrolling = false,
  scrollable = false,
  onTogglePlayPause = () => { },
  onSelectTile = () => { },
  tilesLenght = 0,
  selectedIndex,
}) => {
  const hasValidCountDown = !!countdownTarget && isValidCountDownDate(countdownTarget)
  const shouldShowSlide = (
    scrollable
    && tilesLenght > 1
    && !hasValidCountDown
  )
  return (
    <div
      id="banner-header"
      className="bg-banner px-4 h-14 md:h-12 flex flex-col md:flex-row text-center text-white w-full items-center justify-center"
    >
      <span
        data-testid="sitewide-banner-content"
        className="justify-center"
      >
        <div className="flex items-center justify-center w-full">
          <div className={`w-full ${countdownTarget ? 'mr-8' : ''}`}>
            {
              callToActionUrl ? (
                <Link href={callToActionUrl} className="w-full">
                  <Text
                    size="md"
                    textTag="span"
                    className="text-white text-center w-full"
                  >
                    {messaging}
                  </Text>
                </Link>

              )
                : (
                  <Text
                    size="md"
                    textTag="span"
                    className="text-white text-center w-full"
                  >
                    {messaging}
                  </Text>
                )
            }
          </div>
          {hasValidCountDown && (
            <CountdownTimer
              countdownTargetString={countdownTarget}
            />
          )}
        </div>
      </span>
      {!shouldShowSlide ? null : (
        <div className="md:ml-8 mt-2 md:mt-0 flex items-center justify-center">
          {Array.from(Array(tilesLenght).keys()).map((z, index) => (
            <button
              type="button"
              key={`tile-${z}`}
              aria-label={`Select Banner ${index}`}
              onClick={() => onSelectTile(index)}
              className={`w-2 h-2 rounded ${index === selectedIndex ? 'bg-white' : ''} ${index < tilesLenght - 1 ? 'mr-1' : ''} border border-white inline-block`}
            />
          ))}
          <button
            type="button"
            className="ml-2"
            aria-label={isScrolling ? 'pause banner scrolling' : 'play banner scrolling'}
            onClick={onTogglePlayPause}
          >
            <IconRenderer size="xxs" IconComponent={isScrolling ? Play : Pause} />
          </button>
        </div>
      )}
    </div>
  )
}

export default BannerV1
